<template>
    <AffiliationCard
        :showImage="data.showImage"
        :showSubscribeCta="data.showSubscribeCta"
        :heading="cardData?.title"
        :dek="cardData?.summary?.dek"
        :eyebrow="cardData?.eyebrow"
        :url="cardData?.path"
        :pageContext="context"
        :image="cardData?.summary?.heroBlog?.heroImage"
    />
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const cardData = props.data?.affiliations?.[0]?.content?.[0];
</script>
